import type React from "react";

import { Tabs } from "../Tabs";
import type { TabsProps } from "../types";

export const ContentSwitcherTabs: React.FC<
	React.PropsWithChildren<TabsProps>
> = ({ children, initialTabId, prefix, tabListStyles, ...props }) => (
	<Tabs
		initialTabId={initialTabId}
		prefix={prefix}
		tabListStyles={{
			'[tabindex="-1"]+[tabindex="-1"]': {
				":after": {
					content: '""',
					height: 16,
					position: "absolute",
					borderLeft: "1px solid",
					marginTop: -24,
					color: "strokeDisabledlight",
				},
			},
			display: "inline-flex",
			position: "relative",
			justifyContent: "center",
			alignItems: "center",
			padding: "4xs",
			borderRadius: "rounded",
			backgroundColor: "backgroundLightsubtle",
			paddingLeft: "4xs",
			paddingRight: "4xs",
			gap: 0,
			...tabListStyles,
		}}
		{...props}
	>
		{children}
	</Tabs>
);
