/*
 * ⚠ This file is autogenerated ⚠
 */

import type {
	Illustrations,
	Logos,
	Processors,
	RichIcons,
} from "@loveholidays/design-tokens";

/**
 * Mapping between SVG assets and their filenames.
 *
 * Useful for linking to them.
 */
export const svgFilenames: Record<
	| `Illustration/${Illustrations}`
	| `Logo/${Logos}`
	| `Processor/${Processors}`
	| `RichIcon/${RichIcons}`,
	string
> = {
	"Illustration/Google/Coloured":
		"/design-system/illustration-google-coloured-e4468.svg#l",
	"Illustration/PayPal/Coloured":
		"/design-system/illustration-paypal-coloured-f842f.svg#l",
	"Illustration/Trustpilot/Filled":
		"/design-system/illustration-trustpilot-filled-77e5c.svg#l",
	"Illustration/Trustpilot/Halved":
		"/design-system/illustration-trustpilot-halved-cbbb7.svg#l",
	"Illustration/USP/ATOL": "/design-system/illustration-usp-atol-bf0de.svg#l",
	"Illustration/USP/Confidence":
		"/design-system/illustration-usp-confidence-7775e.svg#l",
	"Illustration/USP/Favourites":
		"/design-system/illustration-usp-favourites-52fba.svg#l",
	"Illustration/USP/Flexibility":
		"/design-system/illustration-usp-flexibility-c4d58.svg#l",
	"Illustration/USP/FreeCancellation":
		"/design-system/illustration-usp-freecancellation-7a1c1.svg#l",
	"Illustration/USP/Insurance":
		"/design-system/illustration-usp-insurance-c57b0.svg#l",
	"Illustration/USP/MobileReviews":
		"/design-system/illustration-usp-mobilereviews-214c2.svg#l",
	"Illustration/USP/PayMonthlyDollar":
		"/design-system/illustration-usp-paymonthlydollar-0c21c.svg#l",
	"Illustration/USP/PayMonthlyEuro":
		"/design-system/illustration-usp-paymonthlyeuro-88b97.svg#l",
	"Illustration/USP/PayMonthlyPound":
		"/design-system/illustration-usp-paymonthlypound-cb388.svg#l",
	"Illustration/USP/PriceMatch":
		"/design-system/illustration-usp-pricematch-0494c.svg#l",
	"Illustration/USP/Refunds":
		"/design-system/illustration-usp-refunds-24c11.svg#l",
	"Illustration/USP/Standards":
		"/design-system/illustration-usp-standards-10a14.svg#l",
	"Illustration/USP/Support":
		"/design-system/illustration-usp-support-ef09c.svg#l",
	"Illustration/USP/Trustpilot":
		"/design-system/illustration-usp-trustpilot-773a9.svg#l",
	"Illustration/USP/Trustpilot2":
		"/design-system/illustration-usp-trustpilot2-2c1d8.svg#l",
	"Logo/ATOL/Dark": "/design-system/logo-atol-dark-39ba7.svg#l",
	"Logo/ATOL/Light": "/design-system/logo-atol-light-f63db.svg#l",
	"Logo/Allianz/Colour": "/design-system/logo-allianz-colour-3d5cd.svg#l",
	"Logo/Allianz/Dark": "/design-system/logo-allianz-dark-e54a5.svg#l",
	"Logo/Allianz/Light": "/design-system/logo-allianz-light-118d2.svg#l",
	"Logo/AllianzPartners/Colour":
		"/design-system/logo-allianzpartners-colour-8d49f.svg#l",
	"Logo/AllianzPartners/Dark":
		"/design-system/logo-allianzpartners-dark-01425.svg#l",
	"Logo/AllianzPartners/Light":
		"/design-system/logo-allianzpartners-light-4ecb2.svg#l",
	"Logo/AppStore/Dark_de-DE":
		"/design-system/logo-appstore-dark_de-de-12695.svg#l",
	"Logo/AppStore/Dark_en-GB":
		"/design-system/logo-appstore-dark_en-gb-c57c3.svg#l",
	"Logo/AppStore/Dark_en-IE":
		"/design-system/logo-appstore-dark_en-ie-ff64d.svg#l",
	"Logo/BayerLeverkusen/Colour":
		"/design-system/logo-bayerleverkusen-colour-dfc87.svg#l",
	"Logo/DRV/Colour": "/design-system/logo-drv-colour-24bc2.svg#l",
	"Logo/DRV/Dark": "/design-system/logo-drv-dark-6f7b9.svg#l",
	"Logo/DRV/Light": "/design-system/logo-drv-light-a74ef.svg#l",
	"Logo/FocusMoney/Colour": "/design-system/logo-focusmoney-colour-9ab9f.svg#l",
	"Logo/GooglePlay/Dark_de-DE":
		"/design-system/logo-googleplay-dark_de-de-cd9a4.svg#l",
	"Logo/GooglePlay/Dark_en-GB":
		"/design-system/logo-googleplay-dark_en-gb-f40fe.svg#l",
	"Logo/GooglePlay/Dark_en-IE":
		"/design-system/logo-googleplay-dark_en-ie-387e1.svg#l",
	"Logo/HolidayCheck/Colour":
		"/design-system/logo-holidaycheck-colour-cdbd8.svg#l",
	"Logo/IATA/LargeDark": "/design-system/logo-iata-largedark-2e057.svg#l",
	"Logo/IATA/LargeLight": "/design-system/logo-iata-largelight-70c61.svg#l",
	"Logo/Loveholidays/LargeColouredDark":
		"/design-system/logo-loveholidays-largecoloureddark-c68d5.svg#l",
	"Logo/Loveholidays/LargeColouredDark_New":
		"/design-system/logo-loveholidays-largecoloureddark_new-9f915.svg#l",
	"Logo/Loveholidays/LargeColouredLight":
		"/design-system/logo-loveholidays-largecolouredlight-a2dd2.svg#l",
	"Logo/Loveholidays/LargeColouredLight_New":
		"/design-system/logo-loveholidays-largecolouredlight_new-689ed.svg#l",
	"Logo/Loveholidays/LargeDark":
		"/design-system/logo-loveholidays-largedark-bbc90.svg#l",
	"Logo/Loveholidays/LargeDark_New":
		"/design-system/logo-loveholidays-largedark_new-0cc93.svg#l",
	"Logo/Loveholidays/LargeLight":
		"/design-system/logo-loveholidays-largelight-b94a3.svg#l",
	"Logo/Loveholidays/LargeLight_New":
		"/design-system/logo-loveholidays-largelight_new-8b535.svg#l",
	"Logo/Lovevacations/LargeColouredDark":
		"/design-system/logo-lovevacations-largecoloureddark-d9f8b.svg#l",
	"Logo/Lovevacations/LargeColouredLight":
		"/design-system/logo-lovevacations-largecolouredlight-584e2.svg#l",
	"Logo/Lovevacations/LargeDark":
		"/design-system/logo-lovevacations-largedark-da51f.svg#l",
	"Logo/Lovevacations/LargeLight":
		"/design-system/logo-lovevacations-largelight-f897e.svg#l",
	"Logo/TUV/Colour": "/design-system/logo-tuv-colour-59afb.svg#l",
	"Logo/TravelAware/Colour":
		"/design-system/logo-travelaware-colour-cd987.svg#l",
	"Logo/TravelAware/Dark": "/design-system/logo-travelaware-dark-ba833.svg#l",
	"Logo/TravelAware/Light": "/design-system/logo-travelaware-light-33ee0.svg#l",
	"Logo/TrustPilot/LargeColoured":
		"/design-system/logo-trustpilot-largecoloured-504e4.svg#l",
	"Logo/VIR/Colour": "/design-system/logo-vir-colour-3b1cb.svg#l",
	"Logo/VIR/Dark": "/design-system/logo-vir-dark-1fa49.svg#l",
	"Logo/VIR/Light": "/design-system/logo-vir-light-e9ab0.svg#l",
	"Logo/VerifiedRyanair/Colour":
		"/design-system/logo-verifiedryanair-colour-e1846.svg#l",
	"Logo/weLoveholidays/LargeColouredDark_New":
		"/design-system/logo-weloveholidays-largecoloureddark_new-109b5.svg#l",
	"Logo/weLoveholidays/LargeColouredLight_New":
		"/design-system/logo-weloveholidays-largecolouredlight_new-8e1e8.svg#l",
	"Logo/weLoveholidays/LargeDark_New":
		"/design-system/logo-weloveholidays-largedark_new-8cf53.svg#l",
	"Logo/weLoveholidays/LargeLight_New":
		"/design-system/logo-weloveholidays-largelight_new-8477d.svg#l",
	"Processor/Amex": "/design-system/processor-amex-de6aa.svg#l",
	"Processor/ApplePay": "/design-system/processor-applepay-46970.svg#l",
	"Processor/DirectDebitDE":
		"/design-system/processor-directdebitde-1ebfe.svg#l",
	"Processor/GooglePay": "/design-system/processor-googlepay-890b6.svg#l",
	"Processor/InvoiceDE": "/design-system/processor-invoicede-9af11.svg#l",
	"Processor/Mastercard": "/design-system/processor-mastercard-70366.svg#l",
	"Processor/PayPal": "/design-system/processor-paypal-396e1.svg#l",
	"Processor/Sofort": "/design-system/processor-sofort-34e9e.svg#l",
	"Processor/Visa": "/design-system/processor-visa-94f48.svg#l",
	"Processor/klarna": "/design-system/processor-klarna-d1c7a.svg#l",
	"RichIcon/Bookings": "/design-system/richicon-bookings-6b6c4.svg#l",
	"RichIcon/Favourites": "/design-system/richicon-favourites-ec9e4.svg#l",
	"RichIcon/PersonalDetails":
		"/design-system/richicon-personaldetails-b98e5.svg#l",
	"RichIcon/Prices": "/design-system/richicon-prices-1eea8.svg#l",
	"RichIcon/RoomOptions": "/design-system/richicon-roomoptions-c10a8.svg#l",
	"RichIcon/SettingsAndSubscriptions":
		"/design-system/richicon-settingsandsubscriptions-bd419.svg#l",
	"RichIcon/Transfers": "/design-system/richicon-transfers-8040e.svg#l",
	"RichIcon/Vouchers": "/design-system/richicon-vouchers-2511d.svg#l",
};
