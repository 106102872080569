import type React from "react";
import { createContext, useContext } from "react";

export enum DOMInteractionEvent {
	CLICK = "click",
	KEYUP = "keyup",
}

export interface TrackingContextValue {
	trackEvent: (
		event: DOMInteractionEvent,
		action: string,
		label?: string,
	) => void;
}

const defaultState: TrackingContextValue = {
	trackEvent: () => undefined,
};

export const TrackingProviderContext =
	createContext<TrackingContextValue>(defaultState);

interface TrackingProviderProps {
	trackEvent: (
		event: DOMInteractionEvent,
		action: string,
		label?: string,
	) => void;
}

export const TrackingProvider: React.FC<
	React.PropsWithChildren<TrackingProviderProps>
> = ({ trackEvent, children }) => (
	<TrackingProviderContext.Provider value={{ trackEvent }}>
		{children}
	</TrackingProviderContext.Provider>
);

export const useTrackingContext = () => useContext(TrackingProviderContext);
