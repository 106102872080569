import React, { useEffect, useState } from "react";

import { ControlledTabs } from "./ControlledTabs";
import type { TabChildren, TabsProps } from "./types";

type UncontrolledTabsProps = TabsProps & {
	tabs: TabChildren;
};

export const UncontrolledTabs = (props: UncontrolledTabsProps) => {
	const { initialTabId, tabs, onTabChanged = () => {}, ...rest } = props;

	const initialTabState = initialTabId || tabs[0].props.id;

	const [activeTabId, setActiveTabId] = useState(initialTabState);

	useEffect(() => {
		setActiveTabId(initialTabState);
	}, [initialTabState]);

	return (
		<ControlledTabs
			activeTabId={activeTabId}
			onTabChanged={(tabId) => {
				setActiveTabId(tabId);
				onTabChanged(tabId);
			}}
			tabs={tabs}
			{...rest}
		/>
	);
};
