import type React from "react";

import {
	HelmetComponentProvider,
	type HelmetComponentValue,
} from "./HelmetComponentProvider";
import {
	PublicPathProvider,
	type PublicPathProviderProps,
} from "./PublicPathProvider";
import { RouterLinkProvider, type RouterLinkValue } from "./RouterLinkProvider";
import {
	type TrackingContextValue,
	TrackingProvider,
} from "./TrackingProvider";
import {
	TranslationProvider,
	type TranslationProviderProps,
} from "./TranslationProvider";
import { WindowSizeProvider } from "./WindowSizeProvider";

export type DesignSystemProviderProps = HelmetComponentValue &
	PublicPathProviderProps &
	RouterLinkValue &
	TrackingContextValue &
	TranslationProviderProps;

export const DesignSystemProvider: React.FC<
	React.PropsWithChildren<DesignSystemProviderProps>
> = ({
	children,
	publicPath,
	trackEvent,
	HelmetComponent,
	Link,
	historyPush,
	useHref,
	locale,
	translations,
	translationNamespace,
}) => (
	<PublicPathProvider publicPath={publicPath}>
		<TrackingProvider trackEvent={trackEvent}>
			<HelmetComponentProvider helmetComponent={HelmetComponent}>
				<RouterLinkProvider
					Link={Link}
					historyPush={historyPush}
					useHref={useHref}
				>
					<TranslationProvider
						locale={locale}
						translations={translations}
						translationNamespace={translationNamespace}
					>
						<WindowSizeProvider>{children}</WindowSizeProvider>
					</TranslationProvider>
				</RouterLinkProvider>
			</HelmetComponentProvider>
		</TrackingProvider>
	</PublicPathProvider>
);
